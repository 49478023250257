<template>
  <MModal
    class="seller-setting-strategy-modal"
    :close-by-outside-click="false"
    :header-with-border="false"
  >
    <template v-slot:modal-header>
      <div class="setting-strategy-modal__header">
        <div class="setting-strategy-modal__header-info">
          <h3 class="setting-strategy-modal__title">
            Стратегия для кампании
          </h3>

          <div class="setting-strategy-modal__header-detail-info">
            <div class="setting-strategy-modal__header-title">
              <MProviderIcon
                v-if="channelIcon"
                :icon="channelIcon"
                :style="{width: '16px', height: '16px'}"
                class="setting-strategy-modal__channel-logo"
              />

              <span
                v-if="campaignName"
                :title="campaignName"
                :aria-label="campaignName"
                class="setting-strategy-modal__campaign-name"
              >
                {{ campaignName }}
              </span>
            </div>

            <span class="setting-strategy-modal__header-xid">
              {{ campaignItem.campaign_xid }}
            </span>

            <span class="setting-strategy-modal__header-placement-type">
              {{ placementName }}
            </span>
          </div>
        </div>

        <div
          class="setting-strategy-modal__close"
          @click="handleCancel"
        >
          <MIcon
            :icon="iconCross"
          />
        </div>
      </div>
    </template>

    <template v-slot:modal-content>
      <div
        ref="formContent"
        class="setting-strategy-modal__content"
        :class="{'_has-scroll': hasScrollFormContent}"
      >
        <div class="setting-strategy-modal__section">
          <h4 class="setting-strategy-modal__block-title">
            Какая у вас цель?
          </h4>

          <SkeletonLoader
            height="80px"
            width="100%"
            :is-loading="isLoading || !goalList.length"
          >
            <div class="setting-strategy-modal__goal-list">
              <TitleDescBlockButton
                v-for="goal in goalList"
                :key="goal.id"
                :title="goal.title"
                :description="goal.description"
                :active="goal.id === activeGoalId"
                @pick-goal="handleChangeGoal(goal.id)"
              />
            </div>
          </SkeletonLoader>
        </div>

        <div class="setting-strategy-modal__section">
          <h4 class="setting-strategy-modal__block-title">
            Стратегия
          </h4>

          <SkeletonLoader
            height="62px"
            width="100%"
            :is-loading="isLoading || !getAutostrategyChoicesList.length"
          >
            <MSelectControl
              :value="currentAutostrategyId"
              :disabled="isLoading"
              :choices="getAutostrategyChoicesList"
              is-in-modal
              large-with-icon
              text-attr="name"
              label-key="isRecommended"
              description-key="description"
              @input="handleChangeAutostrategy"
            />
          </SkeletonLoader>
        </div>

        <div class="setting-strategy-modal__section">
          <SkeletonLoader
            height="62px"
            width="100%"
            :is-loading="isLoading || !getCurrentAutostrategy"
          >
            <SellerAutostrategyFormBlock
              :current-autostrategy="getCurrentAutostrategy"
              :channel-id="channelId"
              :values="formValues"
              :errors="formErrors"
              @change-value="handleChangeValue"
            />
          </SkeletonLoader>
        </div>
        <InfoPanel />
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="setting-strategy-modal__action-footer-block">
        <template v-if="hasStrategy">
          <MButton
            :disabled="isLoading"
            @click="handleSave"
          >
            Сохранить
          </MButton>

          <Switcher
            class="setting-strategy-modal__switcher"
            :state="strategyIsRun"
            :disabled="isLoading"
            @switched="onSwitchStatus"
          />
        </template>

        <template v-else>
          <MButton
            :disabled="isLoading"
            @click="handleCreate"
          >
            Запустить стратегию
          </MButton>

          <MButton
            :disabled="isLoading"
            secondary
            @click="handleCancel"
          >
            Отменить
          </MButton>
        </template>
      </div>
    </template>
  </MModal>
</template>

<script>
  import iconCross from 'assets/icons/cross.svg';
  import Switcher from 'modules/common/components/Switcher.vue';
  import {mapActions, mapGetters} from 'vuex';
  import {FETCH_AUTOSTRATEGY_LIST} from 'store/modules/seller/autostrategySeller';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import translation from 'translation';
  import {getIconConnectionById} from 'utils/seller/sellerConnectionData';
  import {MProviderIcon, SkeletonLoader, MSelectControl} from '@marilyn/marilyn-ui';
  import {getPlacementTypeNameById} from 'utils/seller/sellerPlacementUtils';
  import {goalList} from 'utils/seller/autostrategy/goals';
  import TitleDescBlockButton from 'components/seller/common/TitleDescBlockButton.vue';
  import {uniqueById} from 'utils/array/uniqueById';
  import SellerAutostrategyFormBlock from 'components/seller/campaings/SellerAutostrategyFormBlock';
  import {campaignsSellerService} from 'services/seller';
  import isNumber from 'utils/number';
  import InfoPanel from '../common/InfoPanel.vue';
  import moment from 'moment';

  export default {
    name: 'SellerSettingStrategyModal',
    components: {
      Switcher,
      MProviderIcon,
      TitleDescBlockButton,
      SkeletonLoader,
      MSelectControl,
      SellerAutostrategyFormBlock,
      InfoPanel
    },
    props: {
      campaignItem: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        iconCross,
        goalList: goalList(),
        isLoading: false,
        formErrors: {},
        strategyIsRun: true,
        hasScrollFormContent: false,
        currentGroup: null,
        activeGoalId: null,
        currentAutostrategyId: null,
        formValues: {}
      };
    },
    computed: {
      ...mapGetters('sellerAutostrategy', ['autostrategyList']),
      hasStrategy () {
        return Boolean(this.groupId);
      },
      hasStrategyNotSaveChanges () {
        if (this.hasStrategy) {
          return this.currentGroup.autostrategy_id !== this.currentAutostrategyId;
        }

        return false;
      },
      hasFormNotSaveChanges () {
        if (this.hasStrategy) {
          const formValueNormalize = {};
          Object.keys(this.formValues).forEach(key => {
            const value = this.formValues[key];
            formValueNormalize[key] = isNumber(value) ? String(value) : value;
          });

          const currentGroupValueNormalize = {};
          Object.keys(this.currentGroup.autostrategy_arguments_values).forEach(key => {
            const value = this.currentGroup.autostrategy_arguments_values[key];
            currentGroupValueNormalize[key] = isNumber(value) ? String(value) : value;
          });

          return JSON.stringify(currentGroupValueNormalize) !== JSON.stringify(formValueNormalize);
        }

        return false;
      },
      groupId () {
        return this.campaignItem.group_id;
      },
      campaignName () {
        return this.campaignItem.name;
      },
      placementName () {
        return getPlacementTypeNameById(this.campaignItem.placement_type);
      },
      channelId () {
        return this.campaignItem.channel_id;
      },
      channelIcon () {
        return getIconConnectionById(this.channelId);
      },
      activeGoalItem () {
        return this.goalList.find(goal => goal.id === this.activeGoalId);
      },
      getCurrentAutostrategy () {
        if (!this.currentAutostrategyId) {
          return null;
        }

        return this.getAutostrategyChoicesList.find(autostrategy => autostrategy.id === this.currentAutostrategyId);
      },
      argumentsList () {
        return this.getCurrentAutostrategy?.arguments || [];
      },
      getAutostrategyChoicesList () {
        const isWB = this.channelId === 23;
        const isOZON = this.channelId === 21;

        const wbSuffix = {
          WILDBERRIES_AUTO: 'WB_AD_AUTO',
          WILDBERRIES_AUCTION: 'WB_AD_AUCTION'
        }[this.campaignItem.placement_type];

        const ozonSuffix = {
          OZON_SKU: 'OZON_SKU_AD',
          OZON_SKU_TOP: 'OZON_SKU_TOP_AD'
        }[this.campaignItem.placement_type];

        const filteredAutostrategyList = this.autostrategyList.filter(autostrategy => {
          const forCurrentChannel = autostrategy.channel_ids.includes(this.channelId);
          const forCurrentGoal = this.activeGoalItem?.availableAutostrategyTypeList.includes(autostrategy.id);

          if (isWB) {
            return forCurrentChannel && forCurrentGoal && autostrategy.id.endsWith(wbSuffix);
          } else if (isOZON) {
            return forCurrentChannel && forCurrentGoal && autostrategy.id.endsWith(ozonSuffix);
          }
          return forCurrentChannel && forCurrentGoal;
        });

        const normalizeAutostrategyList = filteredAutostrategyList.map(autostrategy => ({
          ...autostrategy,
          name: autostrategy.nameSeller || autostrategy.name,
          description: autostrategy.descriptionSeller || autostrategy.description,
          isRecommended: autostrategy.is_seller_recommended ? 'РЕКОМЕНДУЕМ' : null
        }));

        const sortedAutostrategyList = normalizeAutostrategyList.sort(a => a.isRecommended ? -1 : 1);

        return uniqueById(sortedAutostrategyList, 'id');
      },
      paramsForSave () {
        let returnParams = {
          autostrategy_arguments_values: this.formValues,
          autostrategy_id: this.currentAutostrategyId,
          channel_id: this.channelId,
          enabled: this.strategyIsRun,
          force_autocampaign_save: true,
          is_logging_enabled: true,
          labels: [],
          name: this.campaignName,
          placement_ids: [this.campaignItem.id]
        };

        if (this.hasStrategy) {
          returnParams.id = this.currentGroup.id;
        }

        return returnParams;
      }
    },
    watch: {
      getCurrentAutostrategy: {
        handler () {
          this.setValueForForm();
          this.formErrors = {};
        },
        immediate: true
      }
    },
    async beforeMount () {
      await this.fetchData();
      this.fillDataToForm();
    },
    mounted () {
      this.checkScrollFormContent();
      window.addEventListener('resize', this.checkScrollFormContent);
    },
    update () {
      this.checkScrollFormContent();
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.checkScrollFormContent);
    },
    methods: {
      ...mapActions('sellerAutostrategy', {
        fetchAutostrategyList: FETCH_AUTOSTRATEGY_LIST
      }),
      checkScrollFormContent () {
        this.$nextTick(() => {
          const el = this.$refs.formContent;
          if (el) {
            this.hasScrollFormContent = el.scrollHeight > el.clientHeight;
          }
        });
      },
      async fetchCurrentGroup () {
        if (this.hasStrategy) {
          const currentGroup = await campaignsSellerService.fetchDetailGroup(this.groupId);
          const sequenceValue = currentGroup.autostrategy_arguments_values.OPTIONAL_EXCLUSIVE_QUERIES;
          if (!Array.isArray(sequenceValue) || !sequenceValue.length ||
            sequenceValue.length === 1 && !sequenceValue[0]) {
            currentGroup.autostrategy_arguments_values.OPTIONAL_EXCLUSIVE_QUERIES = [];
          }

          this.currentGroup = currentGroup;
        }
      },
      setValueForForm () {
        const fillValues = {};
        this.argumentsList.forEach(arg => {
          const hasArgFromLegacyStrategy = arg.id in this.formValues;

          fillValues[arg.id] = hasArgFromLegacyStrategy ? this.formValues[arg.id] : null;
        });

        this.formValues = fillValues;

        if (this.hasStrategy && this.currentGroup?.autostrategy_id === this.currentAutostrategyId) {
          const isDateBeforeRelease = (date) => moment(date).isBefore(moment('2025-04-04'));
          const isUpdatedBeforeRelease = isDateBeforeRelease(this.currentGroup.group_updated_at);
          const isStartedBeforeRelease = isDateBeforeRelease(this.currentGroup.group_started_at);

          if (this.currentGroup.group_updated_at) {
            if (isUpdatedBeforeRelease) {
              this.currentGroup.autostrategy_arguments_values.OPTIONAL_EXCLUSIVE_QUERIES_ENABLED = false;
            }
          } else if (isStartedBeforeRelease) {
            this.currentGroup.autostrategy_arguments_values.OPTIONAL_EXCLUSIVE_QUERIES_ENABLED = false;
          }

          this.formValues = {...this.currentGroup.autostrategy_arguments_values};
        } else {
          this.setDefaultsFormValue();
        }
      },
      setDefaultsFormValue () {
        if (!this.argumentsList.length) {
          return;
        }

        for (const argument of this.argumentsList) {
          if (argument.args !== null && argument.args.default !== undefined) {
            this.handleChangeValue({
              keyValue: argument.id,
              newValue: argument.args.default
            });
          }
        }
      },
      handleChangeValue ({keyValue, newValue}) {
        this.$set(this.formValues, keyValue, newValue);
      },
      fillDataToForm () {
        this.isLoading = true;

        if (this.hasStrategy) {
          this.strategyIsRun = Boolean(this.campaignItem.strategy_enabled);
          this.handleChangeAutostrategy(this.currentGroup.autostrategy_id);
          this.changeGoalValueFromCurrentAutostrategyId();
        } else {
          this.handleChangeGoal(this.goalList[0].id);
        }

        this.isLoading = false;
      },
      async fetchData () {
        this.isLoading = true;

        try {
          await Promise.all([
            this.fetchAutostrategyList(),
            this.fetchCurrentGroup()
          ]);
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            this.$notify({
              type: 'error',
              header: translation.tr('Error'),
              text: e.getErrorMessage() || e.getValidationErrors().amount
            });
          } else {
            throw e;
          }
        }

        this.isLoading = false;
      },
      handleChangeAutostrategy (val) {
        this.currentAutostrategyId = val;
      },
      changeGoalValueFromCurrentAutostrategyId () {
        const currentGoal = this.goalList.find(goal =>
          goal.availableAutostrategyTypeList.includes(this.currentAutostrategyId)
        );

        this.changeGoal(currentGoal.id);
      },
      handleHttpErrors (e) {
        const errors = e.getValidationErrors();
        const message = e.getErrorMessage();
        if (errors) {
          this.formErrors = errors.autostrategy_arguments_values;
          this.$notify({
            type: 'error',
            header: translation.tr('Error'),
            text: message ? message : translation.tr('Please, correct errors'),
            closeByButton: true
          });
          return;
        }
        throw e;
      },
      async handleSave ({fromPrompt = false}) {
        const isAnotherStrategy = this.currentGroup.autostrategy_id !== this.currentAutostrategyId;

        try {
          if (isAnotherStrategy && !fromPrompt) {
            await this.$prompt.show({
              bodyText: 'Сохранить изменение стратегии?<br>Динамика по&nbsp;ДРР&nbsp;и&nbsp;заказам обновится не&nbsp;сразу&nbsp;— сервису понадобится 3&nbsp;дня, чтобы&nbsp;собрать результаты работы новой стратегии.',
              okText: 'Сохранить',
              cancelText: 'Не сохранять',
              sizeModal: 'small'
            });
          }
        } catch (e) {
          return;
        }

        this.isLoading = true;
        try {
          await campaignsSellerService.changeSettingAutostrategy(this.paramsForSave);
          let notifyData;
          if (isAnotherStrategy) {
            notifyData = {
              header: 'Вы изменили стратегию',
              text: `Теперь стратегия «${this.getCurrentAutostrategy.name}»`
            };
          } else {
            notifyData = {
              header: 'Изменения сохранены'
            };
          }
          this.$emit('success-save', notifyData);
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            this.isLoading = false;
            return this.handleHttpErrors(e);
          }
          throw e;
        }

        this.isLoading = false;
      },
      async handleCreate () {
        this.isLoading = true;

        try {
          await campaignsSellerService.createAutostrategy(this.paramsForSave);
          this.$emit('success-create');
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            this.isLoading = false;
            return this.handleHttpErrors(e);
          }
          throw e;
        }

        this.isLoading = false;
      },
      setDefaultAutostrategyValue () {
        try {
          this.handleChangeAutostrategy(this.getAutostrategyChoicesList[0].id);
        } catch (e) {
          this.$notify({
            type: 'error',
            header: 'Ошибка',
            text: 'При загрузке стратегий произошла ошибка.\nПожалуйста, перезагрузите страницу'
          });
          console.warn(e);
        }
      },
      changeGoal (newGoalId) {
        if (newGoalId === this.activeGoalId) {
          return;
        }

        this.activeGoalId = newGoalId;
      },
      handleChangeGoal (newGoalId) {
        this.changeGoal(newGoalId);
        this.setDefaultAutostrategyValue();
      },
      async handleCancel () {
        if (this.hasStrategy) {
          if (this.hasStrategyNotSaveChanges) {
            try {
              await this.$prompt.show({
                bodyText: 'Сохранить изменения?<br>Если сменить стратегию, то статистика будет собираться заново в течение 3-х дней',
                cancelText: 'Не сохранять',
                okText: 'Сохранить',
                sizeModal: 'small'
              });

              this.handleSave({fromPrompt: true});
            } catch (e) {
              this.$emit('cancel');
            }

            return;
          }

          if (this.hasFormNotSaveChanges) {
            try {
              await this.$prompt.show({
                bodyText: 'Сохранить изменения?',
                cancelText: 'Не сохранять',
                okText: 'Сохранить',
                sizeModal: 'small'
              });

              this.handleSave({fromPrompt: true});
            } catch (e) {
              this.$emit('cancel');
            }

            return;
          }
        }

        this.$emit('cancel');
      },
      onSwitchStatus (val) {
        this.$emit('switch-status', val);
        this.strategyIsRun = val;
      }
    }
  };
</script>

<style>
  div.seller-setting-strategy-modal {
    .m-modal__header {
      padding: 20px 12px 0 20px;
    }

    .m-modal__content {
      overflow-y: unset;
    }

    div.m-modal__footer {
      padding: 16px 20px;
    }

    .m-modal__container {
      width: 760px;
      height: 100%;
      max-height: 800px;
    }

    div.m-modal__position-wrapper {
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .setting-strategy-modal__header {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }

  .setting-strategy-modal__close {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    color: var(--secondary-text-color);

    cursor: pointer;

    &:hover {
      color: var(--text-color);
    }
  }

  .setting-strategy-modal__title {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 8px;
  }

  .setting-strategy-modal__action-footer-block {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  .setting-strategy-modal__switcher {
    margin-left: 16px;
  }

  .setting-strategy-modal__content {
    padding: 16px 20px 0 20px;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;

    &._has-scroll {
      padding-right: 8px;
    }
  }

  .setting-strategy-modal__header-title {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .setting-strategy-modal__campaign-name {
    margin-left: 8px;
    color: #242424;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .setting-strategy-modal__header-detail-info {
    display: flex;
    align-items: center;

    > * {
      padding: 9px 8px;
    }
  }

  .setting-strategy-modal__header-xid {
    color: #A8A8A8;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.01em;
  }

  .setting-strategy-modal__header-placement-type {
    color: #242424;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.01em;
  }

  .setting-strategy-modal__channel-logo {
    flex-shrink: 0;
  }

  .setting-strategy-modal__header-info {
    overflow: hidden;
  }

  .setting-strategy-modal__block-title {
    color: #242424;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 12px;
  }

  .setting-strategy-modal__section {
    margin-bottom: 32px;
  }

  .setting-strategy-modal__goal-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    height: 80px;
  }
</style>
