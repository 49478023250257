<template>
  <MTableCellIcon
    :icon="channelIcon"
    :text="itemText"
    :icon-size="{width: '16px', height: '16px'}"
    class="m-table-cell-name-icon"
  />
</template>

<script>
  import MTableCellIcon from 'modules/common/components/MTable/cells/MTableCellIcon';
  import {getIconConnectionById} from 'utils/seller/sellerConnectionData';

  export default {
    name: 'MTableCellNameIcon',
    components: {MTableCellIcon},
    props: {
      channelKey: {
        type: String,
        default: 'channel_id'
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      itemText () {
        return this.tableRow[this.itemKey];
      },
      channelIcon () {
        return getIconConnectionById(this.tableRow[this.channelKey]);
      }
    }
  };
</script>

<style>
  .m-table-cell-name-icon {
    font-size: 12px;
  }
</style>
