body {
  font-family: var(--main-font-family);

  color: #333333;

  padding: 0;

  margin: 0;

  /*
  *  Fix font `some font faced fonts look bold on Mac in Chrome/Webkit based browsers.`
  *  https://www.uv.mx/personal/gvera/stop-fonts-looking-bold-on-mac-browsers/
  */
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
}

body.body_modal-active {
  overflow: hidden;
}

.container {
  max-width: 1360px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: none;
}
