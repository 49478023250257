<template>
  <div>
    <div
      v-if="label"
      class="select-container__label"
    >
      <label class="m-token-input-label">{{ label }}</label>
    </div>
    <div class="m-token-input-wrapper">
      <div
        ref="root"
        class="m-token-input m-token-input__root"
        :class="{'m-token-input__dropdown_open': showDropdown,
                 'm-token-input-multiline': isMultiline}"
      >
        <div
          :class="{
            'm-token-input-size-s': isSmall,
            'm-token-input-size-m': isMedium,
            'm-token-input-size-l': isLarge,
            'm-token-input_disabled': disabled
          }"
          @click="focusOnInput"
        >
          <div
            ref="container"
            class="m-token-input__container"
            :class="{
              'm-token-input__container_size_s': isSmall,
              'm-token-input__container_size_m': isMedium,
              'm-token-input__container_size_l': isLarge
            }"
            tabindex="0"
          >
            <!-- items -->
            <div
              v-for="(item, index) in value"
              :ref="`token-${index}`"
              :key="item + '-' + index"
              class="m-token-input__item"
              :class="{
                'm-token-input__item_size_s': isSmall,
                'm-token-input__item_size_m': isMedium,
                'm-token-input__item_size_l': isLarge
              }"
            >
              <div
                v-overflow-title="item"
                class="m-token-input__item-text"
                :class="{
                  'm-token-input__item-text_size_s': isSmall,
                  'm-token-input__item-text_size_m': isMedium,
                  'm-token-input__item-text_size_l': isLarge
                }"
              >
                {{ item }}
              </div>
              <m-icon
                :icon="icon"
                class="m-token-input__delete-icon"
                @click.native.stop="onDeleteIconClick($event, index)"
              />
            </div><!-- items -->
            <!-- input -->
            <input
              v-show="!disabled"
              ref="input"
              :key="renderInput"
              :value="input"
              type="text"
              :placeholder="inputPlaceholder"
              class="m-token-input__input"
              :class="{
                'm-token-input__input_multiline': isMultiline,
                'm-token-input__input_size_s': isSmall,
                'm-token-input__input_size_m': isMedium,
                'm-token-input__input_size_l': isLarge,
                'm-token-input__input_nonactive': isWarning,
              }"
              @input="onTokenInput"
              @keydown.enter="onKeyUpEnter"
              @focus="setDropdown(true)"
              @keydown.delete="onKeyDownDelete"
              @paste="onTokenPaste"
            > <!-- input -->
          </div> <!-- container -->
        </div> <!-- root -->
        <transition name="m-token-input__transition-scale-y">
          <!-- multiline dropdown -->
          <div
            v-if="showDropdown"
            ref="dropdown"
            class="m-token-input__dropdown"
            :class="{'m-token-input__dropdown_multiline': isMultiline}"
            :style="dropdownPos"
          >
            <div
              class="m-token-input__dropdown-catalog"
            >
              <div
                v-for="(item, key) in filteredChoices"
                :key="`item_${item.id}_${key}`"
                class="m-token-input__dropdown-catalog__item"
                :class="{
                  'm-token-input__dropdown-catalog__item_size_s': isSmall,
                  'm-token-input__dropdown-catalog__item_size_m': isMedium,
                  'm-token-input__dropdown-catalog__item_size_l': isLarge
                }"
                @click="tryToAddToken(item)"
              >
                <span class="m-token-input__dropdown-catalog__item-name">{{ item.name }}</span>
              </div>
            </div>
            <div
              v-if="showDropdownFooter"
              class="m-token-input__dropdown-footer"
            >
              <div
                v-show="!isWarning && !isLimitMaxItem"
                class="m-token-input__add-item-hint"
              >
                {{ addTokenText }}
                <div class="m-token-input__add-item-hint_text_muted">
                  {{ _('Press Enter') }}
                </div>
              </div>
              <div
                v-if="isWarning"
                class="m-token-input__add-item-hint"
              >
                <div class="m-token-input__add-item-hint_text_muted">
                  {{ warningNotFound }}
                </div>
              </div>
              <div
                v-if="isLimitMaxItem"
                class="m-token-input__add-item-hint"
              >
                <div class="m-token-input__add-item-hint_text_muted">
                  {{ warningMaxItems }}
                </div>
              </div>
            </div> <!-- dropdown footer -->
          </div> <!-- multiline dropdown -->
        </transition>
      </div>
    </div>

    <span
      v-if="isLimitMaxItem && warningMaxItems"
      class="m-token-input__warning-max-items"
    >
      {{ warningMaxItems }}
    </span>
  </div>
</template>

<script>
  import CrossIcon from 'assets/icons/cross.svg';
  import debounce from 'lodash.debounce';
  import translation from 'translation';
  import isNumber from 'utils/number';

  export default {
    name: 'MTokenInput',
    props: {
      value: {
        type: Array,
        default: () => []
      },
      maxItems: {
        type: Number,
        default: null
      },
      choices: {
        type: Array,
        default: Array
      },
      size: {
        type: String,
        default: 'm',
        choices: ['s', 'm', 'l']
      },
      allowNonChoice: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: ''
      },
      isNeedShowTextWarningMaxItems: {
        type: Boolean,
        default: false
      },
      pasteTokenSeparator: {
        type: [String, RegExp],
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => /\n/g
      },
      disabled: {
        type: Boolean,
        default: false
      },
      unique: {
        type: Boolean,
        default: false
      },
      warningNotFound: {
        type: String,
        default: () => translation.tr('Item not found')
      },
      warningMaxItems: {
        type: String,
        default: () => translation.tr('The maximum number of elements has been reached')
      },
      label: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        icon: CrossIcon,
        input: null,
        isMultiline: null,
        dropdownPos: null,
        renderInput: 0,
        isDropdownToggled: false,
        tokenAddState: false,
        onTokenInputProcessed: false,
        onTokenPasteProcessed: false
      };
    },
    computed: {
      inputPlaceholder () {
        if (this.value?.length > 0) {
          return '';
        }

        return this.placeholder;
      },
      addTokenText () {
        return translation.tr('Add {0}').format(this.input);
      },
      isWarning () {
        return !this.allowNonChoice && this.input && this.isFilteredChoicesEmpty;
      },
      showDropdown () {
        if (!this.isDropdownToggled) {
          return false;
        }

        if (this.input || this.onTokenInputProcessed) {
          return true;
        }

        return !this.input && this.choicesNotEmpty;
      },
      showDropdownFooter () {
        return this.input && (this.isFilteredChoicesEmpty || this.isLimitMaxItem);
      },
      filteredChoices () {
        if (!this.input) {
          return this.choices;
        }

        const searchTerm = this.input.toLowerCase();

        return this.choices.filter(item => item.name.toLowerCase().startsWith(searchTerm));
      },
      isFilteredChoicesEmpty () {
        return this.filteredChoices.length === 0;
      },
      choicesNotEmpty () {
        return this.choices.length > 0;
      },
      isSmall () {
        return this.size === 's';
      },
      isMedium () {
        return this.size === 'm';
      },
      isLarge () {
        return this.size === 'l';
      },
      isLimitMaxItem () {
        if (this.maxItems === null) {
          return false;
        }

        return this.maxItems <= (this.value?.length || 0);
      }
    },
    watch: {
      value: {
        handler (val) {
          if (!Array.isArray(val)) {
            this.$emit('input', []);
            return;
          }

          this.$nextTick(() => {
            this.isMultiline = this._calcIsMultiline();
            this.$nextTick(() => {
              this.dropdownPos = this._calcDropdownPos();
            });
          });
        },
        immediate: true
      }
    },
    created () {
      document.addEventListener('click', this.onDocumentClick);
    },
    destroyed () {
      document.removeEventListener('click', this.onDocumentClick);
    },
    mounted () {
      this.isMultiline = this._calcIsMultiline();
      this.$nextTick(() => {
        this.dropdownPos = this._calcDropdownPos();
      });
    },
    methods: {
      reRenderInput () {
        this.renderInput = this.renderInput + 1;
      },
      focusOnInput () {
        if (this.disabled) {
          return;
        }
        this.$refs.input.focus();
      },
      setDropdown (v) {
        this.isDropdownToggled = v;
      },
      onDocumentClick (e) {
        const shouldBeOpen = this.$el.contains(e.target);

        this.setDropdown(shouldBeOpen);
      },
      blinkDuplicate (duplicate, index) {
        const item = this.$refs[`token-${index}`][0];
        item.animate([
          {background: '#EAEAEA'},
          {background: '#CEE3F8', offset: 0.20},
          {background: '#CEE3F8', offset: 0.80},
          {background: '#EAEAEA'}
        ], {duration: 200});
      },

      /**
       * Function to add token
       * try - because it can reject new token if unique prop is specified
       */
      tryToAddToken (token) {
        if (token.id) {
          token = {value: token.id, text: token.name};
        }
        if (this.unique) {
          const duplicates = this.value.filter(item => item === token.value);
          if (duplicates.length > 0) {
            return this.blinkDuplicate(duplicates[0], this.value.indexOf(duplicates[0]));
          }
        }
        this.submitTokens([token]);
      },
      tryToAddTokenList (tokensList) {
        if (this.unique) {

          // clear non unique values
          tokensList = Array.from(new Set(tokensList));

          const duplicates = this.value.filter(item => tokensList.indexOf(item) >= 0);

          tokensList = tokensList.filter(item => duplicates.indexOf(item) < 0);
        }

        // filter empty items
        tokensList = tokensList.filter(item => item.length > 0).map(i => ({value: i, text: i}));
        if (isNumber(this.maxItems)) {
          tokensList = tokensList.slice(0, this.maxItems - this.value.length);
        }

        this.submitTokens(tokensList);
        return tokensList.map(i => i.value);
      },
      submitTokens (tokens) {
        this.tokenAddState = false;
        this.$emit('input', [...this.value, ...tokens.map(token => token.text)]);
        this.$nextTick(() => {
          this.$refs.input.focus();
          this.input = '';
        });
      },
      onTokenInput (e) {
        if (this.onTokenPasteProcessed) {
          this.input = null;
          this.reRenderInput();
          return;
        }

        this.onTokenInputProcessed = true;

        let value = e.target.value;

        let needCallFnOnComma = false;

        if (value.endsWith(',')) {
          value = value.replace(',', '');
          needCallFnOnComma = true;
        }

        this.tokenAddState = true;
        this.input = null;
        this.$nextTick(() => {
          this.input = value;

          if (needCallFnOnComma) {
            this.onKeyUpEnter();
          }

          this.onTokenInputProcessed = false;
        });
      },
      checkAllowNonChoice (value) {
        if (!this.allowNonChoice) {
          return Boolean(this.choices.find(el => el.name === value));
        }

        return true;
      },
      onKeyUpEnter () {
        if (this.isLimitMaxItem) {
          return;
        }

        if (!this.checkAllowNonChoice(this.input)) {
          return;
        }

        if (this.input) {
          this.tryToAddToken({value: this.input, text: this.input});
        }
      },

      // debounce to make erase a little bit slower
      onKeyDownDelete: debounce(function () {
        const inputIsEmpty = !this.input || this.input && this.input.length === 0;
        if (!this.tokenAddState && inputIsEmpty) {
          const newValue = [...this.value];
          newValue.splice(newValue.length - 1, 1);
          this.$emit('input', newValue);
        } else if (inputIsEmpty) {

          // delay before starting to erase
          setTimeout(() => {
            this.tokenAddState = false;
          }, 500);
        }
      }, 100, {maxWait: 100}),
      onDeleteIconClick (event, index) {
        if (this.disabled) {
          return;
        }
        const newValue = [...this.value];
        newValue.splice(index, 1);
        this.$emit('input', newValue);
      },
      onTokenPaste () {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        if (this.isLimitMaxItem || !this.checkAllowNonChoice(paste)) {
          return;
        }

        this.onTokenPasteProcessed = true;

        const splittedTokens = paste.split(this.pasteTokenSeparator).filter(Boolean);
        const trimmedTokens = splittedTokens.map(item => item.trim());
        this.tryToAddTokenList(trimmedTokens);

        this.onTokenPasteProcessed = false;

        this.$nextTick(this.reRenderInput);
      },
      _calcVerticalDistance (e1, e2) {
        return e1.getBoundingClientRect().y - e2.getBoundingClientRect().y;
      },
      _calcIsMultiline () {
        let container; let items; let item;

        ({container} = this.$refs);
        items = container.children;
        item = items ? items[items.length - 1] : null;

        if (container && item) {
          return this._calcVerticalDistance(item, container) > item.getBoundingClientRect().height;
        }

        return false;
      },
      _calcDropdownPos () {
        const root = this.$refs.root.getBoundingClientRect();

        if (!this.isMultiline) {
          return {
            position: 'absolute',
            top: `${root.height}px`,
            left: 0,
            width: `${root.width}px`
          };
        }

        const input = this.$refs.input.getBoundingClientRect();
        return {
          position: 'absolute',
          top: `${input.y - root.y + input.height}px`,
          left: `${input.x - root.x}px`
        };
      }
    }
  };
</script>

<style>
  .m-token-input-wrapper {
    position: relative;
  }

  /* root */
  .m-token-input__root {
    --container-border-color: var(--border-color);
    --container-border-radius: var(--border-radius);
    --container-border-color-focus: var(--action-color);
    --container-background-color: var(--background-color);
    --element-h-gap: 4px;
    --element-v-gap-s: 2px;
    --element-v-gap-m: 4px;
    --element-v-gap-l: 6px;
    --element-v-size-s: 22px;
    --element-v-size-m: 22px;
    --element-v-size-l: 24px;
    --text-size-s: 14px;
    --text-size-m: 14px;
    --text-size-l: 16px;
    --text-line-height-s: calc(var(--text-size-s) + 3px);
    --text-line-height-m: calc(var(--text-size-m) + 3px);
    --text-line-height-l: calc(var(--text-size-l) + 3px);
    --item-delete-icon-color: var(--gray-text-color);
    --item-delete-icon-color-hover: var(--text-color);

    overflow-y: auto;

    box-sizing: border-box;

    background-color: var(--container-background-color);

    border: 1px solid var(--container-border-color);

    border-radius: var(--container-border-radius);

    scroll-behavior: smooth;

    &.m-token-input__dropdown_open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .m-token-input-label {
    font-weight: var(--medium-font-weight);
    color: var(--text-color);
  }

  .m-token-input:focus-within {
    border-color: var(--container-border-color-focus);
  }

  .m-token-input-size-s {
    font-size: var(--text-size-s);

    line-height: var(--text-line-height-s);

    padding-top: var(--element-v-gap-s);
  }

  .m-token-input-size-m {
    font-size: var(--text-size-m);

    line-height: var(--text-line-height-m);

    padding-top: var(--element-v-gap-m);
  }

  .m-token-input-size-l {
    font-size: var(--text-size-l);

    line-height: var(--text-line-height-l);

    padding-top: var(--element-v-gap-l);
  }

  /* container */
  .m-token-input__container {
    cursor: text;

    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    box-sizing: border-box;

    margin-left: var(--element-h-gap);
    margin-right: var(--element-h-gap);
  }

  .m-token-input__container_size_s {
    max-height: calc((var(--element-v-size-s) + var(--element-v-gap-s)) * 10 + var(--element-v-gap-s) * 2);
  }

  .m-token-input__container_size_m {
    max-height: calc((var(--element-v-size-m) + var(--element-v-gap-m)) * 10 + var(--element-v-gap-m) * 2);
  }

  .m-token-input__container_size_l {
    max-height: calc((var(--element-v-size-l) + var(--element-v-gap-l)) * 10 + var(--element-v-gap-l) * 2);
  }

  /* item */
  .m-token-input__item {
    cursor: default;

    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    padding-left: 4px;
    padding-right: 4px;

    margin-right: var(--element-h-gap);

    background-color: var(--gray-color);

    border-radius: 2px;
  }

  .m-token-input__item_size_s {
    height: var(--element-v-size-s);
    margin-bottom: var(--element-v-gap-s);
  }

  .m-token-input__item_size_m {
    height: var(--element-v-size-m);
    margin-bottom: var(--element-v-gap-m);
  }

  .m-token-input__item_size_l {
    height: var(--element-v-size-l);
    margin-bottom: var(--element-v-gap-l);
  }

  /* item text */
  .m-token-input__item-text {
    font-family: var(--main-font-family);

    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    max-width: 24ch;  /* stylelint-disable-line unit-whitelist */

    user-select: none;
  }

  .m-token-input__item-text_size_s {
    font-size: var(--text-size-s);
    line-height: var(--text-line-height-s);
  }

  .m-token-input__item-text_size_m {
    font-size: var(--text-size-m);
    line-height: var(--text-line-height-m);
  }

  .m-token-input__item-text_size_l {
    font-size: var(--text-size-l);
    line-height: var(--text-line-height-l);
  }

  .m-token-input input[type='text'].m-token-input__input {
    font: unset;  /* stylelint-disable-line font-weight-notation */
    font-family: var(--main-font-family);

    flex-grow: 1;
    flex-shrink: 0;

    width: 0;

    padding: 0 5px;

    margin: unset;

    background: transparent;

    /* override application.css */
    border: unset;

    &:focus {
      transition: none;
    }

    &.m-token-input__input_size_s {
      font-size: var(--text-size-s);

      height: var(--element-v-size-s);

      margin-bottom: var(--element-v-gap-s);
    }

    &.m-token-input__input_size_m {
      font-size: var(--text-size-m);

      height: var(--element-v-size-m);

      margin-bottom: var(--element-v-gap-m);
    }

    &.m-token-input__input_size_l {
      font-size: var(--text-size-l);

      height: var(--element-v-size-l);

      margin-bottom: var(--element-v-gap-l);
    }

    &.m-token-input__input_multiline {
      min-width: 150px;
    }

    &.m-token-input__input_nonactive {
      color: var(--gray-text-color);
    }
  }

  /* delete icon */
  .m-token-input__delete-icon {
    color: var(--item-delete-icon-color);

    cursor: pointer;

    height: var(--small-icon-size);

    margin: 3px;
  }

  .m-token-input__delete-icon:hover {
    color: var(--item-delete-icon-color-hover);
  }

  /* dropdown */
  .m-token-input__dropdown {
    overflow: auto;

    z-index: var(--dropdown-z-index);

    box-sizing: border-box;
    width: 100%;
    max-height: 200px;

    background-color: var(--background-color);

    border: 1px solid var(--border-color);
    border-top: none;

    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform-origin: top;

    user-select: none;

    & .m-token-input__dropdown-catalog {
      display: flex;

      flex-direction: column;
      align-items: flex-start;

      width: 100%;
    }

    & .m-token-input__dropdown-catalog__item {
      cursor: pointer;

      display: flex;

      align-items: center;

      box-sizing: border-box;
      width: 100%;

      padding-top: var(--element-v-gap-m);
      padding-left: calc(var(--element-h-gap) + 4px);
      padding-bottom: var(--element-v-gap-m);

      transition-duration: 200ms;
      transition-timing-function: ease;
      transition-property: background-color;

      & .m-token-input__dropdown-catalog__item-name {
        text-overflow: ellipsis;

        white-space: nowrap;

        overflow: hidden;
      }
    }

    & .m-token-input__dropdown-catalog__item_size_s {
      font-size: var(--text-size-s);
      height: calc(var(--element-v-size-s) + var(--element-v-gap-s));
    }

    & .m-token-input__dropdown-catalog__item_size_m {
      font-size: var(--text-size-m);
      height: calc(var(--element-v-size-m) + var(--element-v-gap-m));
    }

    & .m-token-input__dropdown-catalog__item_size_l {
      font-size: var(--text-size-l);
      height: calc(var(--element-v-size-l) + var(--element-v-gap-l));
    }

    & .m-token-input__dropdown-catalog__item:hover {
      background-color: var(--hover-background-color);
    }
  }

  .m-token-input-multiline.m-token-input__dropdown_open,
  .m-token-input__dropdown-catalog__item:last-child:hover {
    border-bottom-right-radius: var(--container-border-radius);
    border-bottom-left-radius: var(--container-border-radius);
  }

  .m-token-input__dropdown_multiline {
    width: 150px;

    background-color: var(--background-color);

    border: 1px solid var(--border-color);
  }

  .m-token-input__dropdown-footer {
    box-sizing: border-box;

    padding: 6px 8px 7px;

    border-bottom-right-radius: var(--container-border-radius);
    border-bottom-left-radius: var(--container-border-radius);
  }

  .m-token-input__add-item-hint {
    font-size: 14px;

    line-height: 17px;

    overflow-wrap: break-word;
  }

  .m-token-input__add-item-hint_text_muted {
    font-family: var(--main-font-family);
    font-size: 10px;

    color: var(--gray-text-color);
    line-height: 12px;

    display: flex;

    align-items: center;

    margin-top: 4px;
  }

  .m-token-input__filter-message {
    font-family: var(--main-font-family);
    font-size: 10px;

    color: var(--gray-text-color);
    line-height: 12px;
  }

  .m-token-input .m-token-input_disabled {
    color: var(--gray-text-color);

    overflow: hidden;
    cursor: not-allowed;

    background-color: var(--secondary-button-disabled-color);

    border-color: var(--secondary-button-disabled-color);

    & .m-token-input__container,
    & .m-token-input__item,
    & .m-token-input__input {
      cursor: not-allowed;
    }

    & .m-token-input__delete-icon {
      color: var(--gray-text-color);
      cursor: not-allowed;
    }
  }

  /* transition */
  .m-token-input__transition-scale-y-enter-active,
  .m-token-input__transition-scale-y-leave-active {
    transition: var(--transition-duration);
  }

  .m-token-input__transition-scale-y-enter,
  .m-token-input__transition-scale-y-leave-to {
    transform: scaleY(0);
  }

  .m-token-input__warning-max-items {
    display: inline-block;
    margin-top: 8px;
    color: #696969;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
</style>
